import * as React from "react";
import { httpGet, httpPost, httpPut } from "../services/HttpService";
import { ROUTES } from "../configs/constants";


// Write API's functions below
 
export const companyRegistration = (formData:any) => {
    return httpPost(ROUTES.API.COMPANY_REGISTRATION, formData);
};

export const getCompanyDetails = () => {
    return httpGet(ROUTES.API.GET_COMPANY_DETAILS);
};

export const promoCreation = (formData:any) => {
    return httpPost(ROUTES.API.CREATE_PROMO_CODE, formData);
};

export const getPromoDetails = () => {
    return httpGet(ROUTES.API.GET_PROMO_DETAILS);
};


export const getRegisteredUserDetails = () => {
    return httpGet(ROUTES.API.getregistrationdetail);
}


export const getRegisteredUserDetailById = (UserId:any) => {
    return httpGet(ROUTES.API.getregistrationdetailbyid + "?UserId=" + UserId);
}
export const discountCreation = (formData:any) => {
    return httpPost(ROUTES.API.CREATE_DISCOUNT_CODE, formData);
};

export const getDiscountDetails = () => {
    return httpGet(ROUTES.API.GET_DISCOUNT_DETAILS);
};
 
export const getLoginOtp = (mobileOrEmail:any) => {
    return httpGet(ROUTES.API.getotp + "?mobileOrEmail=" + mobileOrEmail);
};
 
export const verifyLoginOtp = (mobileOrEmail:any , OTP:any) => {
    return httpGet(ROUTES.API.verifyRegistrationOtp + "?mobileOrEmail=" + mobileOrEmail + "&OTP=" + OTP);
};
 
export const insertRegistration = (formData:any) => {
    return httpPost(ROUTES.API.insertregistration, formData);
};
export const userLogin = (mobileOrEmail:any , password:any) => {
    return httpGet(ROUTES.API.userlogin + "?mobileOrEmail=" + mobileOrEmail + "&password=" + password);
};

export const forgotPassword = (mobileOrEmail:any) => {
    return httpGet(ROUTES.API.forgotpassword + "?mobileOrEmail=" + mobileOrEmail);
};

export const verifyForgotPasswordOtp = (mobileOrEmail:any , OTP:any) => {
    return httpGet(ROUTES.API.verifyforgototp + "?mobileOrEmail=" + mobileOrEmail + "&OTP=" + OTP);
};

export const  resetNewPassword = (emailOrNumber:any , newPassword:any, ConformPassword:any) => {
    return httpPost(ROUTES.API.resetnewpassword + "?emailOrNumber=" + emailOrNumber + "&newPassword=" + newPassword +"&ConformPassword=" + ConformPassword);
};

export const getAllbrand = () => {
    return httpGet(ROUTES.API.getmasterbrand)
}
export const insertMasterbrand = (formData:any) => {
    return httpPost(ROUTES.API.insertbrand, formData);
};
export const insertMasterwarehouse = (formData:any) => {
    return httpPost(ROUTES.API.insertwarehouse, formData);
};
export const getAllwarehouse = () => {
    return httpGet(ROUTES.API.getmasterwarehouse)
}
export const insertMastercategory = (formData:any) => {
    return httpPost(ROUTES.API.insertcategory, formData);
};
export const getAllcategory = () => {
    return httpGet(ROUTES.API.getmastercategory)
}
export const getAllsku = () => {
    return httpGet(ROUTES.API.getmastersku)
}
export const insertMastersku = (formData:any) => {
    return httpPost(ROUTES.API.insertsku, formData);
};

export const getAllSubscription = () => {
    return httpGet(ROUTES.API.getallsubscriptionlist)
}

export const insertEnterpriseRegistration = (formData:any) => {
    return httpPost(ROUTES.API.enterpriseregistration, formData);
};
export const insertBulkUploadfile = (formData:any) => {
    return httpPost(ROUTES.API.insertbulkupload, formData);
};


export const insertBrandUploadfile = (formData:any) => {
    return httpPost(ROUTES.API.insertbrandupload, formData);
};
export const insertUserRole = (formData:any) => {
    return httpPost(ROUTES.API.insertuserrole, formData);
};
export const insertWarehouseUploadfile = (formData:any) => {
    return httpPost(ROUTES.API.insertwarehouseupload, formData);
};
export const insertSkuUploadfile = (formData:any) => {
    return httpPost(ROUTES.API.insertskuupload, formData);
};
export const getAllUserRole = () => {
    return httpGet(ROUTES.API.getcompanyuserrole)
}
export const getAllplatform = () => {
    return httpGet(ROUTES.API.getplatform)
}

export const insertCompanyUser = (formData:any) => {
    return httpPost(ROUTES.API.insertcompanyuser, formData);
};

export const getAllCompanyUserDetail = () => {
    return httpGet(ROUTES.API.getcompanyuserroledetail)
}

export const AmazonLoginLink = (userId : any) => {
    return httpGet(ROUTES.API.getAmazonLoginLink + "?userId=" + userId)
}

export const AuthorizeSellerAccount = (AuthCode : any, UserId : any) => {
    return httpGet(ROUTES.API.AuthorizeSeller + "?AuthCode=" + AuthCode + "&UserId=" + UserId)
}

export const getAllcompanydetails = () => {
    return httpGet(ROUTES.API.getcompanydetailslist)
}





