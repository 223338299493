import Logo from "../../assets/images/Accuship-Logo.png";
import Dashboardw from "../../assets/images/Dashboard icon_white.png";
import Dashboardg from "../../assets/images/Dashboard icon_Grey.png";
import Reconcilew from "../../assets/images/Reconciliation_White.png";
import Reconcileg from "../../assets/images/Reconciliation_Grey.png";
import Marketw from "../../assets/images/Marketing Logo White.png";
import Marketg from "../../assets/images/Marketing Logo Grey.png";
import Masterw from "../../assets/images/Master_White.png";
import Masterg from "../../assets/images/Master_Grey.png";
import Userw from "../../assets/images/User_White.png";
import Userg from "../../assets/images/User_Grey.png";
import Search from "../../assets/images/Search.png";
import Add_accnt from "../../assets/images/Add account.png";
import Profile from "../../assets/images/Profile logo_Sample.png";
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../configs/constants";
import "../layouts/style.module.scss"


export default function HeaderMenu() {
  const [index, setindex] = useState(0);
  const [_masterList, _setMasterList] = useState(1);
  const [_marketList, _setMarketList] = useState(1);
  const [_masterDropDown, _setMasterDropDown] = useState(false);
  const [_userDropDown, _setUserDropDown] = useState(false);
  const [_profileDropDown, _setProfileDropDown] = useState(false);
  const [_marketingDropDown, _setMarketingDropDown] = useState(false);
  const [showSupportOptions, setShowSupportOptions] = useState(false);
  const [_accountClicked, _setAccountClicked] = useState(false);
  const [_userList, _setUserList] = useState(1);
  const [clickedMenus, setClickedMenus] = useState({
    profile: false,
    Account: false,
    AccountManagment: false,
    Support: false,
    plan: false,
    call: false,
    email: false,
    phone: false,
  });

  const handleMenuClick = (menu: any) => {
    debugger;
    const updatedMenus: any = {};
    Object.keys(clickedMenus).forEach((key) => {
      updatedMenus[key] = key === menu ? true : false;
    });
    setClickedMenus(updatedMenus);
  };


  useEffect(() => {
    _setProfileDropDown(false);
  }, [clickedMenus]);

  useEffect(() => {
    _setMasterDropDown(false);
  }, [_masterList, _setMasterDropDown]);
  useEffect(() => {
    _setUserDropDown(false);
  }, [_userList, _setUserDropDown]);

  useEffect(() => {
    _setMarketingDropDown(false);
  }, [_marketList, _setMarketingDropDown]);

  useEffect(() => {
    _setProfileDropDown(false);
  }, [_marketList, _setProfileDropDown]);


  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case ROUTES.DASHBOARD.DASHBOARD:
        setindex(0);
        break;
      case ROUTES.DASHBOARD.RECONCILATION:
        setindex(1);
        break;
      case ROUTES.DASHBOARD.MARKETINGDISCOUNTS:
        setindex(2);
        break;
      case ROUTES.DASHBOARD.MARKETINGPROMO:
        setindex(2);
        break;
      case ROUTES.DASHBOARD.MASTERWAREHOUSE:
        setindex(3);
        break;
      case ROUTES.DASHBOARD.MASTERSKU:
        setindex(3);
        break;
      case ROUTES.DASHBOARD.MASTERBRAND:
        setindex(3);
        break;
      case ROUTES.DASHBOARD.MASTERCATEGORY:
        setindex(3);
        break;
      case ROUTES.DASHBOARD.USERROLE:
        setindex(4);
        break;
      case ROUTES.DASHBOARD.USERS:
        setindex(4);
        break;
      default:
        setindex(-1);
        break;
    }
  }, [location.pathname]);


  const handleSupportClick = () => {
    setShowSupportOptions(!showSupportOptions);
  };


  return (
    <div className='row w-100 border rounded m-0' style={{ position: "relative" }}>
      <div className='col-3 col-md-3 d-flex align-items-center justify-content-center border border-bottom-0 border-top-0'>
        <img src={Logo} className='w-50 h-60' />
      </div>
      <div className='col-6 col-md-6 p-3'>
        <ul className='d-flex col-12 justify-content-between align-items-center p-0 m-0'
          style={{ listStyleType: "none" }}>
          <li onClick={() => {
            setindex(0);
            _setMasterList(1);
            _setUserList(1);
            _setMasterDropDown(false);
            _setMarketingDropDown(false);
            _setUserDropDown(false)
            _setProfileDropDown(false)
          }}

            style={{ cursor: "pointer" }}
          >
            <NavLink to={ROUTES.DASHBOARD.DASHBOARD} style={{ textDecoration: "none" }}
              className={`${index === 0
                ? "bg-dark text-white border rounded"
                : "bg-white text-muted border-0 rounded"
                } p-2 d-flex align-items-center`}
            >
              <img src={index === 0 ? Dashboardw : Dashboardg} className='mx-1' />
              Dashboard
            </NavLink>
          </li>
          <li
            onClick={() => {
              setindex(1);
              _setMasterList(1);
              _setUserList(1);
              _setMasterDropDown(false);
              _setMarketingDropDown(false);
              _setUserDropDown(false);
              _setProfileDropDown(false)
            }}
            style={{ cursor: "pointer" }}
          >
            <NavLink to={ROUTES.DASHBOARD.RECONCILATION} style={{ textDecoration: "none" }}
              className={`${index === 1
                ? "bg-dark text-white border rounded"
                : "bg-white text-muted border-0 rounded"
                } p-2 d-flex align-items-center`}
            >
              <img src={index === 1 ? Reconcilew : Reconcileg} className='mx-1' />
              Reconciliation
            </NavLink>
          </li>

          <li onClick={() => {
            setindex(2);
            _setMarketingDropDown(!_marketingDropDown);
            _setUserList(1);
            _setUserDropDown(false)
            _setMasterDropDown(false);
            _setProfileDropDown(false)
          }}
            style={{ cursor: "pointer", zIndex: "9999" }}
          >
            <span style={{ textDecoration: "none" }}
              className={`${index === 2
                ? "bg-dark text-white border rounded"
                : "bg-white text-muted border-0 rounded"
                } p-2 d-flex align-items-center`}
            >
              <img src={index === 2 ? Marketw : Marketg} className='mx-1' />
              Marketing
            </span>
            {_marketingDropDown === true && (
              <div className="bg-dark text-white marketList " style={{ position: "absolute" }} >
                <div className={`${_marketList === 1
                  ? "discounts_active"
                  : ""
                  } text-white text-center`}
                  onClick={() => {
                    _setMarketList(1);
                  }}>
                  <NavLink to={ROUTES.DASHBOARD.MARKETINGDISCOUNTS} style={{ textDecoration: "none" }}
                    className="text-white"
                  >
                    Discounts
                  </NavLink>
                </div>
                <div
                  className={`${_marketList === 2
                    ? "promo_active"
                    : ""
                    } text-white text-center`}
                  onClick={() => {
                    _setMarketList(2)
                    _setMasterDropDown(false);
                  }}>
                  <NavLink to={ROUTES.DASHBOARD.MARKETINGPROMO} style={{ textDecoration: "none" }}
                    className="text-white"
                  >
                    Promo
                  </NavLink>
                </div>
              </div>
            )}
          </li>

          <li
            //  onMouseEnter={() => {
            //   setindex(3);
            //   _setUserList(1);
            //   _setMasterDropDown(!_masterDropDown);
            //   _setMarketingDropDown(false);
            //   _setUserDropDown(false);
            //   _setProfileDropDown(false);
            // }}
            onClick={() => {
              setindex(3);
              _setUserList(1);
              _setMasterDropDown(!_masterDropDown);
              _setMarketingDropDown(false);
              _setUserDropDown(false)
              _setProfileDropDown(false)
            }}
            style={{ cursor: "pointer", zIndex: "9999" }}
            className={`${index === 3
              ? "bg-dark text-white border rounded"
              : "bg-white text-muted border-0 rounded"
              } p-2 align-items-center`}
          >

            <span style={{ textDecoration: "none" }}
              className={`${index === 3
                ? "bg-dark text-white rounded"
                : "bg-white text-muted rounded"
                } d-flex align-items-center`}>
              <img src={index === 3 ? Masterw : Masterg} className='mx-1' />
              Master
            </span>
            {_masterDropDown && (
              <div className="mt-1 bg-dark text-white masterList " style={{ position: "absolute" }} >
                <div className={`${_masterList === 1
                  ? "warehouse_active"
                  : ""
                  } text-white text-center`}
                  onClick={() => {
                    _setMasterList(1);
                  }}>
                  <NavLink to={ROUTES.DASHBOARD.MASTERWAREHOUSE} style={{ textDecoration: "none" }}
                    className="text-white"
                  >
                    Warehouse
                  </NavLink>
                </div>
                <div
                  className={`${_masterList === 2
                    ? "sku_active"
                    : ""
                    } text-white text-center`}
                  onClick={() => {
                    _setMasterList(2)
                    _setMasterDropDown(false);
                  }}>
                  <NavLink to={ROUTES.DASHBOARD.MASTERSKU} style={{ textDecoration: "none" }}
                    className="text-white"
                  >
                    Sku
                  </NavLink>
                </div>
                <div
                  className={`${_masterList === 3
                    ? "brand_active"
                    : ""
                    } text-white text-center`}
                  onClick={() => {
                    _setMasterList(3)
                    _setMasterDropDown(false);
                  }}>
                  <NavLink to={ROUTES.DASHBOARD.MASTERBRAND} style={{ textDecoration: "none" }}
                    className="text-white"
                  >
                    Brand
                  </NavLink>
                </div>
                <div
                  className={`${_masterList === 4
                    ? "category_active"
                    : ""
                    } text-white text-center`}
                  onClick={() => {
                    _setMasterList(4)
                    _setMasterDropDown(false);
                  }}>
                  <NavLink to={ROUTES.DASHBOARD.MASTERCATEGORY} style={{ textDecoration: "none" }}
                    className="text-white"
                  >
                    Category
                  </NavLink>
                </div>
                <div className="text-center">step level</div>
              </div>
            )}
          </li>

          <li className=""
            onClick={() => {
              setindex(4);
              _setUserDropDown(!_userDropDown)
              _setMasterList(1);
              _setMasterDropDown(false);
              _setMarketingDropDown(false);
              _setProfileDropDown(false)
            }}
            style={{ cursor: "pointer", zIndex: "9999" }}
          >
            <span style={{ textDecoration: "none" }} className={`${index === 4
              ? "bg-dark text-white rounded"
              : "bg-white text-muted border-0 rounded"
              } p-2 d-flex align-items-center`}
            >
              <img src={index === 4 ? Userw : Userg} className='mx-1' />
              User
            </span>
            {_userDropDown && (
              <div className="bg-dark text-white userList" style={{ position: "absolute" }}>
                <div
                  className={`${_userList === 1
                    ? "warehouse_active text-white"
                    : ""
                    } text-white text-center`}
                  onClick={() => {
                    _setUserList(1)
                    _setUserDropDown(false)
                  }}
                >
                  <NavLink to={ROUTES.DASHBOARD.USERROLE} style={{ textDecoration: "none" }}
                    className="text-white"
                  // className={`${_userList === 1
                  //   ? "warehouse_active"
                  //   : ""
                  //   } text-white`}
                  >
                    Role
                  </NavLink>
                </div>

                <div className={`${_userList === 2
                  ? "warehouse_active"
                  : ""
                  } text-white text-center`}
                  onClick={() => {
                    _setUserList(2)
                    _setUserDropDown(false)
                  }}
                >
                  <NavLink to={ROUTES.DASHBOARD.USERS} style={{ textDecoration: "none" }}
                    className="text-white"
                  // className={`${_userList === 2
                  //   ? "warehouse_active"
                  //   : ""
                  //   } text-white`}
                  >
                    Users
                  </NavLink>

                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
      <div className='col-3 col-md-3 border border-bottom-0 border-top-0 justify-content-start align-items-center d-flex' style={{ cursor: "pointer" }}>
        <img src={Search} className='me-4' />
        <span onClick={() => {
          setindex(5)
          _setProfileDropDown(false)
          _setMasterDropDown(false);
          _setMarketingDropDown(false);
          _setUserDropDown(false);

        }}>
          <NavLink to={ROUTES.DASHBOARD.CONNECTACCOUNT}>
            <img src={Add_accnt} className='me-4' />
          </NavLink>
        </span>

        <span onClick={() => {
          _setProfileDropDown(!_profileDropDown)
          _setMasterDropDown(false);
          _setMarketingDropDown(false);
          _setUserDropDown(false);
        }}>
          <span>
            <img src={Profile} className='me-5' />
          </span>
          {_profileDropDown && (
            <div className="overlay">
              <div className="border" style={{ cursor: "pointer", backgroundColor: 'white' }} >
                {/* <div className={`mx-2 p-1 ${clickedMenus.Account ? 'text-dark' : 'text-muted'}`} onClick={() => { handleMenuClick('account'); setShowSupportOptions(false) }}  >Account</div>
                <hr className="m-0 p-0"></hr> */}
                <div className="mx-2 p-1">
                  <NavLink to={ROUTES.DASHBOARD.PROFILE} className={` ${clickedMenus.profile ? 'text-dark' : 'text-muted'}`} style={{ textDecoration: "none" }} onClick={() => { handleMenuClick('profile'); setShowSupportOptions(false) }} >
                    My Profile
                  </NavLink>
                </div>
                <hr className="m-0 p-0"></hr>
                <div className={`mx-2 p-1 ${clickedMenus.AccountManagment ? 'text-dark' : 'text-muted'}`} onClick={() => { handleMenuClick('AccountManagment'); setShowSupportOptions(false) }}> Account Managment</div>
                <hr className="m-0 p-0"></hr>
                <div className={`mx-2 p-1 ${clickedMenus.plan ? 'text-dark' : 'text-muted'}`} onClick={() => { handleMenuClick('plan'); setShowSupportOptions(false) }}>
                  <NavLink to={ROUTES.DASHBOARD.PLANDETAILS} className="text-muted" style={{ textDecoration: "none" }}>
                    Plans
                  </NavLink>
                </div>
                <hr className="m-0 p-0"></hr>
                <div className="position-relative border overlay">
                  <div className={`mx-2 p-1 ${clickedMenus.Support ? 'text-dark' : 'text-muted'}`} onClick={handleSupportClick}> Support</div>
                  {showSupportOptions && (
                    <div className="mx-2 p-1 position-absolute support_list border" style={{ cursor: "pointer", backgroundColor: 'white' }}>
                      <ul className="list-unstyled mb-0">
                        <NavLink to={ROUTES.DASHBOARD.CALLSUPPORT} className="text-muted " style={{ textDecoration: "none" }}>
                          <li className="mx-2 text-muted" onClick={() => handleMenuClick('call')}>Call Support</li>
                        </NavLink>
                        <hr className="m-0 p-0"></hr>
                        <NavLink to={ROUTES.DASHBOARD.EMAILSUPPORT} className="text-muted active" style={{ textDecoration: "none" }}>
                          <li className="mx-2" onClick={() => handleMenuClick('email')}>Email Support</li>
                        </NavLink>
                        <hr className="m-0 p-0"></hr>
                        <NavLink to={ROUTES.DASHBOARD.CRMINTEGRATION} className="text-muted active" style={{ textDecoration: "none" }}>
                          <li className="mx-2" onClick={() => handleMenuClick('crm')}>CRM Integration</li>
                        </NavLink>
                      </ul>
                    </div>
                  )}
                </div>
                <hr className="m-0 p-0"></hr>
                <div className="mx-2 p-1">
                  <NavLink to={ROUTES.LOGIN} className="text-muted" style={{ textDecoration: "none" }}>
                   <span className="text-danger">Log out</span>  <span className="mx-2 text-danger"><LogoutIcon style={{fontSize:"medium"}}/></span>
                  </NavLink>
                </div>
              </div>
            </div>
          )}
        </span>
      </div>
    </div>
  );
}
