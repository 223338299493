const baseRoute = "/";
export let baseApi = "https://accushipapi.sysmedac.com/api/";
if (process.env.NODE_ENV === "production") {
  baseApi = "https://accushipapi.sysmedac.com/api/";
}

export const ROUTES = {
  // HOME: baseRoute,
  LOGIN: baseRoute + "",
  LOGINVERIFY: baseRoute + "login/verify",
  OTPVERIFY: baseRoute + "login/otpverify",
  LOGINOTPVERIFY : baseRoute + "login/loginotpverify",
  HOME: baseRoute + "home",
  THANKSPAGE: baseRoute + "ThankYou",

  REGISTRATION: {
    REGISTER: baseRoute + "login/register",
    REGISTERSUCCESS: baseRoute + "login/registersuccess",
  },

  ONBOARDING: {
    ONBOARD: baseRoute + "onboard",
    PLANS: baseRoute + "onboard/plans",
    CUSTOMPLAN: baseRoute + "onboard/customplan",
    PAYMENT : baseRoute + "onboard/payment"
  },

  DASHBOARD: {
    HOME: baseRoute + "",
    DASHBOARD: baseRoute + "dashboard",
    RECONCILATION:baseRoute + "reconcilation",
    BANKREPORT:baseRoute + "bankreport",
    MARKETINGPROMO: baseRoute + "marketingpromo",
    MARKETINGDISCOUNTS: baseRoute + "marketingdiscounts",
    MASTERWAREHOUSE:baseRoute + "masterwarehouse",
    MASTERSKU:baseRoute + "mastersku",
    MASTERBRAND:baseRoute + "masterbrand",
    MASTERCATEGORY:baseRoute + "mastercategory",
    USERROLE:baseRoute+ "userrole",
    USERS:baseRoute+"users",
    CONNECTACCOUNT:baseRoute + "connectaccount",
    PROFILE:baseRoute+ "profile",
    CALLSUPPORT: baseRoute + "CALLSUPPORT",
    EMAILSUPPORT: baseRoute + "EMAILSUPPORT",
    CRMINTEGRATION: baseRoute + "CRMINTEGRATION",
    PLANDETAILS : baseRoute + "plandetails",
  },
  //
  API: {
    COMPANY_REGISTRATION    : baseApi + "Registration/CompanyRegistration/InsertOrUpdateCompanyDetailsSub",
    GET_COMPANY_DETAILS     : baseApi + "Registration/CompanyRegistration/GetCompanyDetailsSub",
    CREATE_PROMO_CODE       : baseApi + "PromoCode/InsertOrUpdatePromoCode",
    GET_PROMO_DETAILS       : baseApi + "PromoCode/GetAllPromoCodes",
    CREATE_DISCOUNT_CODE    : baseApi + "Discount/InsertOrUpdateDiscount",
    GET_DISCOUNT_DETAILS    : baseApi + "Discount/GetAllDiscounts",
    getotp                              : baseApi + 'Registration/OtpVerify/GetOtp',
    verifyRegistrationOtp               : baseApi + 'Registration/OtpVerify/VerifyOtp',
    insertregistration                  : baseApi + 'Registration/AdminRegistration/InsertOrUpdateAccshipAccount',
    getregistrationdetail               : baseApi + 'Registration/AdminRegistration/GetCompanyDetails',
    getregistrationdetailbyid           : baseApi + 'Registration/AdminRegistration/GetCompanyDetailsById',
    userlogin                           : baseApi + 'Registration/Login/CompanyUserLogin',  
    forgotpassword                      : baseApi + 'Registration/Login/ForgetLoginPassword',
    verifyforgototp                     : baseApi + 'Registration/Login/VerifyForgetOTP',
    resetnewpassword                    : baseApi + 'Registration/Login/ResetNewPassword',
    getmasterbrand                      : baseApi + 'Master/MasterBrand/GetMasterBrand',
    insertbrand                         : baseApi + 'Master/MasterBrand/InsertOrUpdateMasterBrand',
    insertwarehouse                     : baseApi + 'Master/MasterWarehouse/InsertOrUpdateMasterWarehouse',
    getmasterwarehouse                  : baseApi + 'Master/MasterWarehouse/GetMasterWarehouse',
    insertcategory                      : baseApi + 'Master/MasterCategory/InsertOrUpdateMasterCategory',
    getmastercategory                   : baseApi + 'Master/MasterCategory/GetMasterCategory',
    getallsubscriptionlist              : baseApi + 'Subscription/GetAllSubcriptionPlanList',
    enterpriseregistration              : baseApi + 'Registration/EnterpriseRegistration/InsertOrUpdateEnterprisePlan',
	  insertsku                           : baseApi + 'Master/MasterSku/InsertOrUpdateMasterSku',
    getmastersku                        : baseApi + 'Master/MasterSku/GetMasterSku',
    insertuserrole                      : baseApi + 'Registration/CompanyRegistration/InsertOrUpdateCompanyConfigUserRole',
    getcompanyuserrole                  : baseApi + 'Registration/CompanyRegistration/GetCompanyConfigUserDetails',
    insertcompanyuser                   : baseApi + 'Registration/CompanyRegistration/InsertOrUpdateCompanyUser',
    getcompanyuserroledetail            : baseApi + 'Registration/CompanyRegistration/GetCompanyUserDetails' ,
    getcompanydetailslist               : baseApi + 'Registration/CompanyRegistration/GetCompanyDetails',
    insertbulkupload                    :baseApi+'Master/bulkupload/InsertOrUpdateMasterCategory',
    insertbrandupload                   :baseApi+'Master/bulkupload/InsertOrUpdateMasterBrand',
    insertwarehouseupload               :baseApi+'Master/bulkupload/InsertOrUpdateMasterWarehouse',
    insertskuupload                     :baseApi+'Master/bulkupload/InsertOrUpdateMasterSku',
    getplatform                         :baseApi+'Master/MasterWarehouse/GetMasterPlatforms',
    getAmazonLoginLink                         :baseApi+'Ticket/SellerCentral/AmazonLogin',
    AuthorizeSeller                         :baseApi+'Ticket/SellerCentral/AUthorize',

  }
 
};




