import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import AxiosProvider from "../providers/AxiosProvider";
import LoaderControl from "../components/helpers/loaderControl";
import { ROUTES } from "../configs/constants";
import AuthLayout from "../components/layouts/authLayout";
import DashboardLayout from "../components/layouts/dashboardLayout";
import Login from "../pages/auth/Login/Login";
import LoginVerification from "../pages/auth/Login/LoginVerification";
import OTP from "../pages/auth/Login/OTP";
import LoginOtpVerify from "../pages/auth/Login/LoginOtpVerify";
import Registration from "../pages/auth/Registration/Register";
import ThankYou from "../pages/home/thankspage/thankYou";

const NotFound = lazy(() => import("../pages/notFound"));
const Plans = lazy(() => import("../pages/onboard/plans"));
const CustomPlan = lazy(() => import("../pages/onboard/plans/customplan"))
const RegisterSuccess = lazy(() => import("../pages/auth/RegistrationSuccess"));
const Dashboard = lazy(() => import("../pages/home/dashboard"));
const Reconcilation = lazy(() => import("../pages/home/reconcilation"));
// const BankReport = lazy(()=> import ("../pages/home/bankreport"));
// const MarketingPromo = lazy(()=> import ("../pages/home/marketing-promo"));
// const MarketingDiscounts = lazy(()=> import ("../pages/home/marketing-discounts"));
// const MasterWareHouse = lazy(()=> import ("../pages/home/master-warehouse"));
// const MasterSKU = lazy(()=> import ("../pages/home/master-sku"));
// const MasterBrand = lazy(()=> import ("../pages/home/master-brand"));
// const MasterCategory = lazy(()=> import ("../pages/home/master-category"));
// const USERROLE = lazy(()=>import("../pages/home/user-role"));
// const USERS = lazy(()=>import("../pages/home/user-users"));
// const CONNECTACCOUNT = lazy(()=>import("../pages/home/connectaccount"));
// const PROFILE = lazy(()=>import("../pages/home/profile"));
// const CALLSUPPORT = lazy(()=>import("../pages/home/callsupport"));
// const EMAILSUPPORT = lazy(()=>import("../pages/home/emailsupport"));
// const CRMINTEGRATION = lazy(()=>import("../pages/home/crmintegration"));
const THANKSPAGE = lazy(()=>import("../pages/home/thankspage/thanksBooking"));
const BankReport = lazy(() => import("../pages/home/bankreport"));
const MarketingPromo = lazy(() => import("../pages/home/marketing-promo"));
const MarketingDiscounts = lazy(() => import("../pages/home/marketing-discounts"));
const MasterWareHouse = lazy(() => import("../pages/home/master-warehouse"));
const MasterSKU = lazy(() => import("../pages/home/master-sku"));
const MasterBrand = lazy(() => import("../pages/home/master-brand"));
const MasterCategory = lazy(() => import("../pages/home/master-category"));
const USERROLE = lazy(() => import("../pages/home/user-role"));
const USERS = lazy(() => import("../pages/home/user-users"));
const CONNECTACCOUNT = lazy(() => import("../pages/home/connectaccount"));
const PROFILE = lazy(() => import("../pages/home/profile"));
const CALLSUPPORT = lazy(() => import("../pages/home/callsupport"));
const EMAILSUPPORT = lazy(() => import("../pages/home/emailsupport"));
const CRMINTEGRATION = lazy(() => import("../pages/home/crmintegration"));
const PLANDETAILS = lazy(() => import("../pages/home/plandetails"));
const PAYMENT = lazy(() => import("../pages/home/payment"));


function RouteApp() {
  return (
    <>
      <AxiosProvider />
      <Routes>
        {/* Login and SingUp, mobile and email verify pages */}
        <Route path={ROUTES.LOGIN} element={<AuthLayout />}>
          <Route
            path={ROUTES.LOGIN}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.LOGINVERIFY}
            element={
              <Suspense fallback={<LoaderControl />}>
                <LoginVerification />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.OTPVERIFY}
            element={
              <Suspense fallback={<LoaderControl />}>
                <OTP />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.LOGINOTPVERIFY}
            element={
              <Suspense fallback={<LoaderControl />}>
                <LoginOtpVerify />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.REGISTRATION.REGISTER}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Registration />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.REGISTRATION.REGISTERSUCCESS}
            element={
              <Suspense fallback={<LoaderControl />}>
                <RegisterSuccess />
              </Suspense>
            }
          />
        </Route>
        <Route path={ROUTES.DASHBOARD.HOME} element={<DashboardLayout />}>
          <Route
            path={ROUTES.DASHBOARD.DASHBOARD}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.RECONCILATION}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Reconcilation />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.MARKETINGPROMO}
            element={
              <Suspense fallback={<LoaderControl />}>
                <MarketingPromo />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.MARKETINGDISCOUNTS}
            element={
              <Suspense fallback={<LoaderControl />}>
                <MarketingDiscounts />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.MASTERWAREHOUSE}
            element={
              <Suspense fallback={<LoaderControl />}>
                <MasterWareHouse />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.MASTERSKU}
            element={
              <Suspense fallback={<LoaderControl />}>
                <MasterSKU />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.MASTERBRAND}
            element={
              <Suspense fallback={<LoaderControl />}>
                <MasterBrand />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.MASTERCATEGORY}
            element={
              <Suspense fallback={<LoaderControl />}>
                <MasterCategory />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.USERROLE}
            element={
              <Suspense fallback={<LoaderControl />}>
                <USERROLE />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.USERS}
            element={
              <Suspense fallback={<LoaderControl />}>
                <USERS />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.CONNECTACCOUNT}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CONNECTACCOUNT />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.BANKREPORT}
            element={
              <Suspense fallback={<LoaderControl />}>
                <BankReport />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.PROFILE}
            element={
              <Suspense fallback={<LoaderControl />}>
                <PROFILE />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.CALLSUPPORT}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CALLSUPPORT />
              </Suspense>
            }
          />
           <Route
            path={ROUTES.THANKSPAGE}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ThankYou />
              </Suspense>
            }
          />
            <Route
            path={ROUTES.DASHBOARD.EMAILSUPPORT}
            element={
              <Suspense fallback={<LoaderControl />}>
                <EMAILSUPPORT />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.DASHBOARD.CRMINTEGRATION}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CRMINTEGRATION />
              </Suspense>
            }
          />

          <Route
            path={ROUTES.DASHBOARD.PLANDETAILS}
            element={
              <Suspense fallback={<LoaderControl />}>
                <PLANDETAILS />
              </Suspense>
            }
          />
        

        </Route>
        <Route path={ROUTES.ONBOARDING.ONBOARD}>
          <Route
            path={ROUTES.ONBOARDING.PLANS}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Plans />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.ONBOARDING.CUSTOMPLAN}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CustomPlan />
              </Suspense>
            }
          />
            <Route
            path={ROUTES.ONBOARDING.PAYMENT}
            element={
              <Suspense fallback={<LoaderControl />}>
                <PAYMENT />
              </Suspense>
            }
          />
        </Route>
        {/* <Route path={ROUTES.DASHBOARD.HOME}>
          <Route
            path={ROUTES.DASHBOARD.RECONCILATION}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Reconcilation />
              </Suspense>
            }
          />
        </Route> */}
        <Route
          path={"*"}
          element={
            <Suspense fallback={<LoaderControl />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default RouteApp;
