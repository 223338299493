import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../../assets/scss/style.scss";
import { IMAGES_ICON } from '../../../assets/images/exportImages';
import { LoadingButton } from '@mui/lab';
import { ArrowBackRounded, CheckCircle } from '@mui/icons-material';
import { removeLocalStorage } from '../../../services/AuthService';
import { useStateValue } from '../../../providers/StateProvider';
import { ROUTES } from '../../../configs/constants'
import { useQuery } from '../../../services/HelperService';
import { AuthorizeSellerAccount } from '../../../models/model';
import Swal from 'sweetalert2';

export default function ThankYou({ content = "", buttonName = 'Ok', navigateTo = ROUTES.DASHBOARD.CONNECTACCOUNT }: any) {
    const [{ user }]: any = useStateValue()
    
    console.log(user)
    const navigate = useNavigate();
    const query = useQuery();
    const [_isSuccess, _setIsSuccess] = useState<any>();
    const code = query.get("code");

    const [_loading, _setLoading] = useState<any>(false);

    const goBack = () => {
        AuthorizeSellerAccount(code, 1)
        .then(res => {
            console.log(res?.data?.isSuccess)
            if(res.data?.isSuccess === true)
            {
                console.log(res?.data)
                console.log(res?.data?.isSuccess)
                Swal.fire({
                    title: 'Success!',
                    text: 'Connected',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
                _setIsSuccess(res.data.data.data.isSuccess)
            }
            else{
                Swal.fire({
                    title: 'Failed!',
                    text: 'Unable to Connect with Amzon..!',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                });
                _setIsSuccess(false)
            }
        });
        navigate(navigateTo);
    }

    useEffect(() => {
        // const tempObj = getLocalStorage('addProperty');
        // _setFormData({ propertyType: tempObj?.propertyType })
    }, []);

    return <React.Fragment>
        <div className="thankYouBg vh-100">
            <div className="thankYouBg">
                <div className="col-10 mx-auto row align-items-center justify-content-center">
                    <div className="text-center my-5">
                        {/* <img className="" src={IMAGES_ICON.Logo} alt="icons" draggable={false} height={45} /> */}
                        <div><CheckCircle className="mt-5" style={{ fontSize: "68px", color: "#05bf68", }} /></div>
                        <div className="fs-4 fw-bold">Thank you!</div>

                    </div>
                    <div className="">
                        <div className="row justify-content-center"  style={{ background: "#FF9900" }}>
                            {content}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center gap-4 mobJustify my-3'>
                        <LoadingButton className="btn btn-block backBlackButton" endIcon={<></>} variant="contained" loadingPosition="end"
                            loading={_loading} onClick={goBack}>{buttonName}</LoadingButton>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}
