import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.scss";
import Logo from "../../../assets/images/Accuship-Logo.png";
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { ROUTES } from "../../../configs/constants";
import { forgotPassword, getLoginOtp, resetNewPassword, userLogin, verifyForgotPasswordOtp } from "../../../models/model";
import Swal from 'sweetalert2';
import { LoadingButton } from "@mui/lab";
import { useStateValue } from "../../../providers/StateProvider";


export default function Login() {
  const [{ userId }, dispatch]: any = useStateValue();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [_forgotPassword, _setForgotPassword] = useState(false);
  const [_forgotOtp, _setforgotOtp] = useState(false);
  const [_confirmPassword, _setConfirmPassword] = useState(false);
  const [_login, _setLogin] = useState(true)
  const [_forgotPasswordButton, _setForgotPasswordButton] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(120);
  const [resendDisabled, setResendDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let timer: any;
    if (timeRemaining > 0) {
      timer = setTimeout(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
    }

    return () => clearTimeout(timer);
  }, [timeRemaining]);

  const handleResendCode = () => {
    setResendDisabled(true);
    setTimeRemaining(120);
    forgotpasswordotp();
  };

  const formatTime = (seconds: any) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const [_formData, _setFormData] = useState<any>({
    Id: 0,
    mobileOrEmail: "",
    MobileNumber: "",
    Email: "",
    OTP: "",
    password: "",
    newPassword: "",
    ConformPassword: "",
  });

  const changeFormData = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
  };

  const loginValidate = {
    mobileOrEmail: { error: false, message: "" },
    Email: { error: false, message: "" },
    password: { error: false, message: "" },
    newPassword: { error: false, message: "" },
    ConformPassword: { error: false, message: "" },
  }

  const [_loginValidate, setLoginevalidate] = useState(loginValidate);
  const [_buttonLoading, _setbuttonLoading] = useState(false);
  const [_passButtonLoading, _setPassButtonLoading] = useState(false);

  const userLoginValidate = () => {
    debugger;
    _setbuttonLoading(true);
    const _validate: any = Object.assign({}, loginValidate);
    let valid = true;

    if (_formData.mobileOrEmail === "" || _formData.mobileOrEmail === 0) {
      _validate.mobileOrEmail.error = true;
      _validate.mobileOrEmail.message = "Required Field";
      valid = false;
    }

    if (_formData.password === "" || _formData.password === 0) {
      _validate.password.error = true;
      _validate.password.message = "Required Field";
      valid = false;
    }

    setLoginevalidate(_validate);
    if (!valid) {
      _setbuttonLoading(false);
      return;
    }
    _setbuttonLoading(true);

    login()
    // SetactiveStep(1)
  }
  const forgotPasswordBox = () => {
    debugger;
    _setForgotPassword(true)
    _setForgotPasswordButton(true)
    _setLogin(false)
  }

  const forgotOtp = () => {
    debugger;
    _setLogin(false)
    _setForgotPasswordButton(false)
    _setforgotOtp(true)
  }

  const confirmPassword = () => {
    _setLogin(false)
    _setForgotPassword(false)
    _setForgotPasswordButton(false)
    _setConfirmPassword(true)
  }

  const login = () => {
    debugger;
    userLogin(_formData?.mobileOrEmail, _formData?.password)
      .then(response => {
        if (response.data.status === "Success") {
          Swal.fire({
            title: 'Success!',
            text: 'Login Successfull!',
            icon: 'success',
            confirmButtonText: 'OK',
          });

          const _tempData = response.data.data.data.id;
          dispatch({
            type: "SET_USER",
            user: _tempData,
          });
          localStorage.setItem(
            "accuship_temp",
            JSON.stringify(_tempData)
          );
          console.log(userId)

          // getOtp()

          navigate(ROUTES.DASHBOARD.DASHBOARD)
          _setbuttonLoading(false);
          _setFormData({
            ..._formData,
            mobileOrEmail: "",
            password: "",
          });
          console.log("success");
        }
        else {
          console.log("bad request");
        }
      })
      .catch((err) => {
        console.log(err)
        Swal.fire({
          icon: 'warning',
          //title: 'Warning!',
          text: 'Invalid User.',
          // confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
      ).finally(() => _setbuttonLoading(false));
  }

  //getLoginOtp
  const getOtp = () => {
    debugger;
    getLoginOtp(_formData?.mobileOrEmail)
      .then(response => {
        if (response.data.status === true) {
          Swal.fire({
            title: 'Success!',
            text: 'Otp Sent Successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          console.log("success");
        }
        else {
          console.log("bad request");
        }
      })
      .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
  }


  //forgotpassword

  const forgotPasswordValidate = () => {
    debugger;
    _setPassButtonLoading(false)
    const _validate: any = Object.assign({}, loginValidate);
    let valid = true;

    if (_formData.mobileOrEmail === "" || _formData.mobileOrEmail === 0) {
      _validate.mobileOrEmail.error = true;
      _validate.mobileOrEmail.message = "Required Field";
      valid = false;
    }

    setLoginevalidate(_validate);
    if (!valid) {
      _setPassButtonLoading(false);
      return;
    }
    _setPassButtonLoading(true);

    forgotOtp()

    forgotpasswordotp()
    // SetactiveStep(1)
  }

  const forgotpasswordotp = () => {
    debugger;
    forgotPassword(_formData?.mobileOrEmail)
      .then(response => {
        if (response.data.status === true) {
          Swal.fire({
            title: 'Success!',
            text: 'OTP Sent Successfull!',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          _setPassButtonLoading(false)

          // _setFormData({
          //   ..._formData,
          //   mobileOrEmail: "",
          //   password: "",
          // });
          console.log("success");
        }
        else {
          console.log("bad request");
        }
      })
      .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
  }

  const forgotPasswordOtpVerification = () => {
    debugger;
    verifyForgotPasswordOtp(_formData?.mobileOrEmail, _formData?.OTP)
      .then(response => {
        if (response.data.status === true) {
          Swal.fire({
            title: 'Success!',
            text: 'OTP Verified Successfull!',
            icon: 'success',
            confirmButtonText: 'OK',
          });

          confirmPassword()

          // _setFormData({
          //   ..._formData,
          //   mobileOrEmail: "",
          //   password: "",
          // });

          console.log("success");
        }
        else {
          console.log("bad request");
        }
      })
      .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
  }

  const confirmPasswordDialog = () => {
    debugger;
    const _validate: any = Object.assign({}, loginValidate);
    let valid = true;
    if (_formData.newPassword === "" || _formData.newPassword === 0) {
      _validate.newPassword.error = true;
      _validate.newPassword.message = "Required Field";
      valid = false;
    }
    if (_formData.ConformPassword === "" || _formData.ConformPassword === 0) {
      _validate.ConformPassword.error = true;
      _validate.ConformPassword.message = "Required Field";
      valid = false;
    }

    if (_formData.newPassword !== _formData.ConformPassword) {
      Swal.fire({
        title: 'Password Mismatch!',
        text: 'The passwords should match.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      valid = false;
    }

    setLoginevalidate(_validate);
    if (!valid) {
      _setbuttonLoading(false);
      return;
    }
    _setbuttonLoading(true);



    resetNewPassword(_formData?.mobileOrEmail, _formData?.newPassword, _formData?.ConformPassword)
      .then(response => {
        if (response.data.statusCode === 200) {
          Swal.fire({
            title: 'Success!',
            text: 'Password reset Successfull!',
            icon: 'success',
            confirmButtonText: 'OK',
          });

          _setFormData({
            ..._formData,
            mobileOrEmail: "",
            password: "",
            MobileNumber: "",
            Email: "",
            OTP: "",
            newPassword: "",
            ConformPassword: "",
          });

          _setConfirmPassword(false)
          _setForgotPassword(false)
          _setLogin(true)


          // _setFormData({
          //   ..._formData,
          //   mobileOrEmail: "",
          //   password: "",
          // });

          console.log("success");
        }
        else {
          console.log("bad request");
        }
      })
      .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
  }

  return (
    <React.Fragment>
        <div className='w-75 row d-flex justify-content-center align-items-center'>
          <div className='col-12 border rounded d-flex align-items-center flex-column p-4 overflow-auto scrollable-content'>
            <img src={Logo} className='w-30 h-30 mt-2' />

            {_login && (
              <h5 className='mt-2'>Login to Accuship</h5>
            )}
            {_forgotPassword && (
              <h5 className='mt-2'>Forgot Password!</h5>
            )}
            {_confirmPassword && (
              <h5 className='mt-2'>Reset New Password</h5>
            )}

            {_login && (
              <div className=''>
                <div className='col-12 col-md-12 mt-4 fontsizing'>
                  <label className='form-label'>Registered Email or Phone</label>
                  <TextField type='email' className='form-control'
                    value={_formData?.mobileOrEmail}
                    onChange={(e: any) => changeFormData("mobileOrEmail", e.target.value)}
                    error={_loginValidate?.mobileOrEmail?.error}
                    helperText={_loginValidate?.mobileOrEmail?.message}
                  />
                </div>
                <div className='col-12 col-md-12 mt-4 form-group fontsizing'>
                  <label className='form-label'>Password</label>
                  <TextField
                    className='form-control'
                    variant='outlined'
                    value={_formData?.password}
                    onChange={(e: any) => changeFormData("password", e.target.value)}
                    error={_loginValidate?.password?.error}
                    helperText={_loginValidate?.password?.message}
                    type={showPassword ? "text" : "password"}
                    // onChange={someChangeHandler}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            style={{ background: "transparent" }}
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div className='text-info col-12 col-md-12 mt-4 d-flex justify-content-end fontsizing'>
                    <span className='text-info' onClick={forgotPasswordBox} style={{ cursor: "pointer" }}>
                      Forgot Password?
                    </span>
                  </div>
                  <LoadingButton
                    className='text-white col-12 col-md-12 mt-4 bg-dark p-3 d-flex justify-content-center align-items-center'
                    style={{ cursor: "pointer" }}
                    loading={_buttonLoading}
                    loadingPosition="end"
                    endIcon={<></>}
                    onClick={() => {
                      // navigate(ROUTES.LOGINOTPVERIFY);
                      userLoginValidate()
                    }}>
                    Login
                  </LoadingButton>
                </div>
              </div>
            )}
            {_forgotPassword && (
              <div>
                <div className='col-12 col-md-12 mt-4 fontsizing'>
                  <label className='form-label'>Enter Registered Email or Phone</label>
                  <TextField type='email' className='form-control'
                    value={_formData?.mobileOrEmail}
                    onChange={(e: any) => changeFormData("mobileOrEmail", e.target.value)}
                    error={_loginValidate?.mobileOrEmail?.error}
                    helperText={_loginValidate?.mobileOrEmail?.message}
                  />
                </div>

                {_forgotOtp && (
                  <div>
                    <div className='col-12 col-md-12 mt-4 fontsizing'>
                      <label className='form-label'>Enter OTP</label>
                      <div className={`form-control`}>
                        {/* <MuiOtpInput  /> */}
                        <input
                          type={"text"}
                          maxLength={6}
                          value={_formData?.OTP}
                          onChange={(e: any) => changeFormData("OTP", e.target.value)}
                          className='col-12 col-md-12 p-3'
                          style={{
                            border: "none",
                            letterSpacing: "2rem",
                            fontSize: "medium",
                            caretColor: "black",
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-12 mt-2 d-flex justify-content-between fontsizing'>
                      <p>Time Remaining: {formatTime(timeRemaining)}</p>
                      {/* <p className='text-muted'  onClick={handleResendCode} disabled={resendDisabled}>Resend Code</p> */}
                      <button className='text-muted' style={{ border: "none", backgroundColor: "white", cursor: "pointer" }} onClick={handleResendCode} disabled={resendDisabled}>
                        Resend OTP
                      </button>
                    </div>
                  </div>
                )}

                {_forgotPasswordButton && (
                  <LoadingButton
                    className='text-white col-12 col-md-12 mt-4 bg-dark p-3 d-flex justify-content-center align-items-center'
                    loading={_passButtonLoading}
                    loadingPosition="end"
                    endIcon={<></>}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      forgotPasswordValidate()
                      // navigate(ROUTES.LOGINOTPVERIFY);
                      // userLoginValidate()
                    }}>
                    Get OTP to Reset Your Password
                  </LoadingButton>
                )}

                {(_forgotOtp && (
                  <div
                    className='text-white col-12 col-md-12 mt-4 bg-dark p-3 d-flex justify-content-center align-items-center'
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      //  confirmPassword()
                      forgotPasswordOtpVerification()
                      // navigate(ROUTES.LOGINOTPVERIFY);
                      // userLoginValidate()
                    }}>
                    Verify Otp
                  </div>
                ))}
              </div>
            )}

            {_confirmPassword && (
              <div>
                <div className='col-12 col-md-12 mt-4 form-group fontsizing'>
                  <label className='form-label'>Password</label>
                  <TextField
                    className='form-control'
                    variant='outlined'
                    value={_formData?.newPassword}
                    onChange={(e: any) => changeFormData("newPassword", e.target.value)}
                    error={_loginValidate?.newPassword?.error}
                    helperText={_loginValidate?.newPassword?.message}
                    type={showPassword ? "text" : "password"}
                    // onChange={someChangeHandler}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            style={{ background: "transparent" }}
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className='col-12 col-md-12 mt-4 form-group fontsizing'>
                  <label className='form-label'>Confirm Password</label>
                  <TextField
                    className='form-control'
                    variant='outlined'
                    value={_formData?.ConformPassword}
                    onChange={(e: any) => changeFormData("ConformPassword", e.target.value)}
                    error={_loginValidate?.ConformPassword?.error}
                    helperText={_loginValidate?.ConformPassword?.message}
                    type={showPassword ? "text" : "password"}
                    // onChange={someChangeHandler}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            style={{ background: "transparent" }}
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div
                  className='text-white col-12 col-md-12 mt-4 bg-dark p-3 d-flex justify-content-center align-items-center'
                  onClick={() => {
                    confirmPasswordDialog()
                    // navigate(ROUTES.DASHBOARD.DASHBOARD);
                    // userLoginValidate()
                  }}>
                  Submit
                </div>
              </div>
            )}

            <div className='col-12 col-md-12 mt-2 d-flex justify-content-center fontsizing'>
              <p>Don't have an account?</p>
              <a
                href=''
                onClick={() => {
                  navigate(ROUTES.REGISTRATION.REGISTER);
                }}
                className='text-info'>
                Create account
              </a>
            </div>
            <div className='col-12 col-md-12 mt-2 d-flex justify-content-center fontsizing'>
              <a href='' className='text-info'>
                ADMIN
              </a>
            </div>
            <div className='col-12 col-md-12 mt-2 d-flex justify-content-center fontsizing'>
              <p>Version 2.3.1</p>
            </div>
          </div>
        </div>
     

    </React.Fragment>
  );
}
