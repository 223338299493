import {
  Check,
  CheckCircle,
  Circle,
  RadioButtonUncheckedOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../configs/constants";
// import { MuiOtpInput } from "mui-one-time-password-input";
import Logo from "../../../assets/images/Accuship-Logo.png";
import Verified from "../../../assets/images/Verified.png";
import Swal from 'sweetalert2';
import { validemail } from "../../../services/HelperService";
import { getLoginOtp, insertRegistration, verifyLoginOtp } from "../../../models/model";

export default function Registration() {
  const [showPassword, setShowPassword] = useState(false);
  const [_confirmPassword, _setConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmPassword = () => _setConfirmPassword(!_confirmPassword);

  const navigate = useNavigate();
  const [ph, setph] = useState("");
  const [otp, setotp] = useState("");
  const [type, settype] = useState("");
  const [phverified, setphverified] = useState(false);
  const [emailverified, setemailverified] = useState(false);
  const [sentotp, setsentotp] = useState(false);
  const [_buttonLoading, _setbuttonLoading] = useState(false);
  const [activeStep, SetactiveStep] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [maskedPassword, setMaskedPassword] = useState('');


  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };


  const maskPassword = (password: any) => {
    debugger;
    return '*'.repeat(password.length);
  };


  const handleAgreementValidation = () => {
    if (!isChecked) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please agree to Amazon\'s Privacy Policy and Conditions of use!',
      });
    }
  };

  const getOtp = (val: any) => {
    debugger;
    if (val === "phone") {
      val = _formData?.MobileNumber;
      getLoginOtp(val)
        .then(response => {
          if (response.data.status === true) {
            Swal.fire({
              title: 'Success!',
              text: 'Otp Sent Successfully',
              icon: 'success',
              confirmButtonText: 'OK',
            });
            console.log("success");
            settype("phone")
            setsentotp(true)
          }
          else {
            console.log("bad request");
            Swal.fire({
              // icon: 'warning',
              //title: 'Warning!',
              text: 'Mobile Number Already Exist.',
              // confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            });
          }
        })
        .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
    }
    else {
      val = _formData?.Email;
      getLoginOtp(val)
        .then(response => {
          if (response.data.status === true) {
            Swal.fire({
              title: 'Success!',
              text: 'Otp Sent Successfully',
              icon: 'success',
              confirmButtonText: 'OK',
            });
            console.log("success");
            settype("email")
            setsentotp(true)
          }
          else {
            console.log("bad request");
            Swal.fire({
              // icon: 'warning',
              //title: 'Warning!',
              text: 'Email Already Exist.',
              // confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            });
          }
        })
        .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
    }
  }

  const verifyMobileOtp = () => {
    const _validate: any = Object.assign({}, mobileOtpValidate);
    let valid = true;

    if (_formData.OTP === "" || _formData.OTP === 0) {
      Swal.fire({
        text: 'Please Enter OTP',
        confirmButtonText: 'OK',
      });
    }
    setRegMobilevalidate(_validate);
    if (!valid) {
      return;
    }
    debugger;
    verifyLoginOtp(_formData?.MobileNumber, _formData?.OTP)
      .then(response => {
        if (response.data.status === true) {
          Swal.fire({
            title: 'Success!',
            text: 'Otp Verified Successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          setphverified(true)
          settype("")
          setsentotp(false)
          _setFormData({
            ..._formData,
            OTP: "",
          });

          console.log("success");
        }
        else {
          Swal.fire({
            icon: 'warning',
            //title: 'Warning!',
            text: 'Invalid OTP',
            // confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          setphverified(false)
        }
      })
      .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
  }


  const verifyEmailOtp = () => {
    const _validate: any = Object.assign({}, mobileOtpValidate);
    let valid = true;

    if (_formData.OTP === "" || _formData.OTP === 0) {
      Swal.fire({
        text: 'Please Enter OTP',
        confirmButtonText: 'OK',
      });
    }
    setRegMobilevalidate(_validate);
    if (!valid) {
      return;
    }
    debugger;
    verifyLoginOtp(_formData?.Email, _formData?.OTP)
      .then(response => {
        debugger;
        if (response.data.status === true) {
          Swal.fire({
            title: 'Success!',
            text: 'Otp Verified Successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          setemailverified(true)
          settype("")
          setsentotp(false)
          _setFormData({
            ..._formData,
            OTP: "",
          });

          console.log("success");
        }
        else {
          debugger;
          console.log("bad request");
          Swal.fire({
            icon: 'warning',
            //title: 'Warning!',
            text: 'Invalid OTP',
            // confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          setemailverified(false)
        }
      })
      .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
  }

  const [_formData, _setFormData] = useState<any>({
    Id: 0,
    MobileNumber: "",
    Email: "",
    OTP: "",
    password: "",
    confirmPassword: "",
    name: "",
    primaryContactPerson: "",
  });

  const changeFormData = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
  };

  // const changeFormData = (name: string, value: any) => {
  //   debugger;
  //   let updatedValue = value;
  //   if (name === 'password') {
  //     updatedValue = maskPassword(value);
  //     setMaskedPassword(updatedValue); // Update the masked password state
  //     _setFormData({ ..._formData, [name]: value }); // Update the form data with the actual password value
  //   }else{
  //     _setFormData({ ..._formData, [name]: value });

  //   }
  // };



  const [_mobileOtp, _setMobileOtp] = useState<any>('')

  const mobileOtpValidate = {
    MobileNumber: { error: false, message: "" },
    Email: { error: false, message: "" },
    password: { error: false, message: "" },
    confirmPassword: { error: false, message: "" },
    name: { error: false, message: "" },
    primaryContactPerson: { error: false, message: "" },
    OTP: { error: false, message: "" }
  }

  const [_regMobileValidate, setRegMobilevalidate] = useState(mobileOtpValidate);

  const registerFirstStep = () => {
    debugger;
    const _validate: any = Object.assign({}, mobileOtpValidate);
    let valid = true;

    if (_formData.MobileNumber === "" || _formData.MobileNumber === 0) {
      _validate.MobileNumber.error = true;
      _validate.MobileNumber.message = "Required Field";
      valid = false;
    }
    if (_formData.Email === "" || _formData.Email === 0) {
      _validate.Email.error = true;
      _validate.Email.message = "Required Field";
      valid = false;
    }
    if (emailverified === false) {
      Swal.fire({
        // title: 'Warning!',
        text: 'Please Verify Email.',
        // confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      valid = false;
    }
    if (phverified === false) {
      Swal.fire({
        // icon: 'warning',
        //title: 'Warning!',
        text: 'Please Verify Phone Number.',
        // confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      valid = false;
    }

    if (_formData.password === "" || _formData.password === 0) {
      _validate.password.error = true;
      _validate.password.message = "Required Field";
      valid = false;
    }
    if (_formData.confirmPassword === "" || _formData.confirmPassword === 0) {
      _validate.confirmPassword.error = true;
      _validate.confirmPassword.message = "Required Field";
      valid = false;
    }
    if (_formData.password !== _formData.confirmPassword) {
      Swal.fire({
        title: 'Password Mismatch!',
        text: 'The passwords should match.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      valid = false;
    }


    if (emailverified === true && phverified === true && _formData.password !== "" && _formData.confirmPassword !== "" && !isChecked) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please agree to Amazon\'s Privacy Policy and Conditions of use!',
      });
      valid = false;
    }

    setRegMobilevalidate(_validate);
    if (!valid) {
      _setbuttonLoading(false);
      return;
    }
    _setbuttonLoading(true);

    SetactiveStep(1)
  }

  const registrationOtpVerify = (val: any) => {
    _setFormData({
      ..._formData,
      OTP: ""
    })
    debugger;
    const _validate: any = Object.assign({}, mobileOtpValidate);
    let valid = true;

    if (val === "email") {
      if (_formData.Email === "") {
        _validate.Email.error = true;
        _validate.Email.message = "Required Field";
        valid = false;
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: 'Please Enter Your Email.',
          // confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
      if (!validemail(_formData.Email)) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: 'Please Enter Valid Email',
          // confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        return false;
      }
      getOtp(val)
    }
    else {
      if (_formData.MobileNumber === "") {
        _validate.MobileNumber.error = true;
        _validate.MobileNumber.message = "Required Field";
        valid = false;
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: 'Please Enter Your Mobile Number.',
          // confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        return false;
      }
      getOtp(val)
    }
  }


  const Registration = () => {

    const _validate: any = Object.assign({}, mobileOtpValidate);
    let valid = true;

    if (_formData.name === "" || _formData.name === 0) {
      _validate.name.error = true;
      _validate.name.message = "Required Field";
      valid = false;
    }
    if (_formData.primaryContactPerson === "" || _formData.primaryContactPerson === 0) {
      _validate.primaryContactPerson.error = true;
      _validate.primaryContactPerson.message = "Required Field";
      valid = false;
    }
    setRegMobilevalidate(_validate);
    if (!valid) {
      _setbuttonLoading(false);
      return;
    }
    _setbuttonLoading(true);

    debugger;
    const data = {
      id: 0,
      mobileNumber: _formData?.MobileNumber,
      email: _formData?.Email,
      password: _formData?.password,
      confirmpassword: _formData?.confirmPassword,
      name: _formData?.name,
      primaryContactPerson: _formData?.primaryContactPerson,
      UserRoleId: 6,
    }

    insertRegistration(data).then((response: any) => {
      debugger;
      if (response.data.status === true) {
        Swal.fire({
          title: 'Success!',
          text: 'Registered Successfully',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        navigate(ROUTES.REGISTRATION.REGISTERSUCCESS)
        // SetactiveStep(1);
      } else {
        //  swal("Something went wrong!", { icon: "warning" });
      }
    });
  };


  useEffect(() => {
    if (phverified && emailverified) {
      settype("");
    }
  }, [phverified, emailverified]);


  // useEffect(() => {
  //   window.scroll(0, 0);
  //   if (type === "email" || type === "phone") {
  //     setsentotp(true);
  //   }
  // }, [type]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);



  return (
    <React.Fragment>
      <div className='w-75 row d-flex justify-content-center align-items-center'>
        <div className='col-12 border rounded d-flex align-items-center flex-column p-4 overflow-auto scrollable-content'>
          <img src={Logo} className='w-30 h-30 mt-2' />
          <h5 className='mt-5 text-center '>Create Accuship Account</h5>
          <Stepper
            activeStep={activeStep}
            className={`col-12 col-md-12 mt-4`}
            alternativeLabel>
            <Step
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "#0070C1", // circle color (COMPLETED)
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "E2E2E2", // Just text label (COMPLETED)
                },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "#0070C1", // circle color (ACTIVE)
                },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "#0070C1", // Just text label (ACTIVE)
                },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  fill: "white", // circle's number (ACTIVE)
                },
                //   "& .MuiStepConnector-line.MuiStepConnector-lineHorizontal.css-z7uhs0-MuiStepConnector-line": {
                //     borderColor: '#0070C1',
                // }
              }}
              className='stepper-circle'>
              <StepLabel className='font-weight-bold'>Create Account</StepLabel>
            </Step>
            <Step
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "#0070C1", // circle color (COMPLETED)
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "E2E2E2", // Just text label (COMPLETED)
                },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "#0070C1", // circle color (ACTIVE)
                },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "#0070C1", // Just text label (ACTIVE)
                },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  fill: "white", // circle's number (ACTIVE)
                },
                "& .MuiStepConnector-line.MuiStepConnector-lineHorizontal.css-z7uhs0-MuiStepConnector-line": {
                  borderColor: '#0070C1',
                }
              }}

              className='stepper-circle'>
              {/* <StepConnector style={{ borderColor: activeStep === 1 ? '#0070C1' : '' }} /> */}

              <StepLabel className='font-weight-bold'>
                Complete Profile
              </StepLabel>
            </Step>
          </Stepper>
          {activeStep === 0 ? (
            <>
              <div className='col-10 col-md-10 mt-4 form-group fontsizing'>
                <label className='form-label'>Mobile Number</label>
                <TextField
                  className='form-control'
                  variant='outlined'
                  type='number'
                  value={_formData?.MobileNumber}
                  onChange={(e: any) => changeFormData("MobileNumber", e.target.value)}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <div style={{ cursor: "pointer" }}>
                          {phverified ? (
                            <img src={Verified} />
                          ) : (
                            <small
                              style={{ fontSize: "smaller", cursor: "pointer" }}
                              className='border-bottom'
                              onClick={() => registrationOtpVerify("phone")}
                            >
                              {type === "phone" ? "Send OTP" : "Verify Phone"}
                            </small>
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              {type === "phone" && (
                <div className='col-9 col-md-9 mt-4 fontsizing'>
                  <label className='form-label'>Enter OTP</label>
                  <div
                    className={`row d-flex border rounded align-items-center justify-content-around`}>
                    <input
                      type={"text"}
                      maxLength={6}
                      value={_formData?.OTP}
                      onChange={(e: any) => changeFormData("OTP", e.target.value)}
                      className='col-9 col-md-9 p-3 rounded'
                      style={{
                        border: "none",
                        letterSpacing: "1rem",
                        fontSize: "medium",
                        caretColor: "black",
                      }}
                    />
                    <div
                      className='p-2 bg-dark text-white col-3 col-md-3 border rounded fontsizing1 text-center'
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        verifyMobileOtp()
                        // type === "phone"
                        //   ? setphverified(true)
                        //   : setemailverified(true);
                      }}>
                      Verify OTP
                    </div>
                  </div>
                  <div className="text-end  my-auto">
                    <span style={{ cursor: "pointer" }} onClick={() => registrationOtpVerify("phone")}> Resend OTP</span>
                  </div>

                  {/* <div className={`row d-flex align-items-center`}>
                <MuiOtpInput
                  value={otp}
                  onChange={(e) => {
                    setotp(e);
                  }}
                  length={4}
                  autoFocus
                  inputMode='numeric'
                  className='col-9 col-md-9'
                />
                <div
                  className='p-2 bg-dark text-white col-3 col-md-3 border rounded fontsizing text-center'
                  onClick={() => {
                    setsentotp(false);
                    type === "phone"
                      ? setphverified(true)
                      : setemailverified(true);
                  }}>
                  Verify OTP
                </div>
              </div> */}

                  <p className='text-success mt-3'>
                    {sentotp ? `OTP has been sent to your ${type}` : ""}
                  </p>
                </div>
              )
              }

              <div className='col-10 col-md-10 mt-4 form-group fontsizing'>
                <label className='form-label'>Email ID</label>
                <TextField
                  className='form-control'
                  value={_formData?.Email}
                  onChange={(e: any) => changeFormData("Email", e.target.value)}
                  variant='outlined'
                  type='text'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <div style={{ cursor: "pointer" }}>
                          {emailverified ? (
                            <img src={Verified} />
                          ) : (
                            <small
                              style={{ fontSize: "smaller", cursor: "pointer" }}
                              className='border-bottom'
                              onClick={() => registrationOtpVerify("email")}
                            >
                              {type === "email" ? "Send OTP" : "Verify Email"}
                            </small>
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>


              {type === "email" && (
                <div className='col-9 col-md-9 mt-4 fontsizing'>
                  <label className='form-label'>Enter OTP</label>
                  <div
                    className={`row d-flex border rounded align-items-center justify-content-around`}>
                    <input
                      type={"text"}
                      maxLength={6}
                      value={_formData?.OTP}
                      onChange={(e: any) => changeFormData("OTP", e.target.value)}
                      className='col-9 col-md-9 p-3 rounded'
                      style={{
                        border: "none",
                        letterSpacing: "1rem",
                        fontSize: "medium",
                        caretColor: "black",
                      }}
                    />
                    <div
                      className='p-2 bg-dark text-white col-3 col-md-3 border rounded fontsizing1 text-center'
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        verifyEmailOtp()
                        // type === "email"
                        //   ? setemailverified(true)
                        //   : setphverified(true);
                      }}>
                      Verify OTP
                    </div>
                  </div>

                  <div className="text-end my-auto">
                    <span onClick={() => registrationOtpVerify("email")} style={{ cursor: "pointer" }}>Resend OTP</span>
                  </div>

                  {/* <div className={`row d-flex align-items-center`}>
                <MuiOtpInput
                  value={otp}
                  onChange={(e) => {
                    setotp(e);
                  }}
                  length={4}
                  autoFocus
                  inputMode='numeric'
                  className='col-9 col-md-9'
                />
                <div
                  className='p-2 bg-dark text-white col-3 col-md-3 border rounded fontsizing text-center'
                  onClick={() => {
                    setsentotp(false);
                    type === "phone"
                      ? setphverified(true)
                      : setemailverified(true);
                  }}>
                  Verify OTP
                </div>
              </div> */}

                  <p className='text-success mt-3'>
                    {sentotp ? `OTP has been sent to your ${type}` : ""}
                  </p>

                </div>
              )
              }


              <div className='col-10 col-md-10 mt-4 form-group fontsizing'>
                <label className='form-label'>Password</label>
                <TextField
                  className='form-control'
                  variant='outlined'
                  type={showPassword ? "text" : "password"}
                  value={_formData?.password}
                  // value={showPassword ? _formData?.password : maskedPassword}
                  onChange={(e: any) => changeFormData("password", e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          style={{ background: "transparent" }}
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          //onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={_regMobileValidate?.password?.error}
                  helperText={_regMobileValidate?.password?.message}
                />
              </div>

              <div className='col-10 col-md-10 mt-4 form-group fontsizing'>
                <label className='form-label'>Confirm Password</label>
                <TextField
                  className='form-control'
                  variant='outlined'
                  value={_formData?.confirmPassword}
                  onChange={(e: any) => changeFormData("confirmPassword", e.target.value)}
                  type={_confirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          style={{ background: "transparent" }}
                          aria-label='toggle password visibility'
                          onClick={handleClickShowConfirmPassword}
                          //onMouseDown={handleMouseDownPassword}
                          >
                          {_confirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={_regMobileValidate?.confirmPassword?.error}
                  helperText={_regMobileValidate?.confirmPassword?.message}
                />
              </div>
              <div className='col-10 col-md-10 mt-4 d-flex justify-content-start align-items-center fontsizing1'>
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  icon={
                    <RadioButtonUncheckedOutlined
                      style={{ color: "#015C9Fs" }}
                    />
                  }
                  checkedIcon={<CheckCircle style={{ color: "#015C9F" }} />}
                />
                Agree to Amazon's
                <a href='' className='text-info mx-1'>
                  Privacy Policy
                </a>
                and
                <a href='' className='text-info mx-1'>
                  Conditions of use
                </a>
              </div>
              <div
                className='text-white text-center col-10 col-md-10 mt-4 bg-dark p-3 d-flex justify-content-center align-items-center'
                style={{ cursor: "pointer" }}
                onClick={() => {
                  registerFirstStep()
                }}>
                Continue to Create Account
              </div>
            </>
          ) : (
            <>
              <div className='col-10 col-md-10 mt-4 form-group fontsizing'>
                <label className='form-label'>
                  Primary Company name or Trade name
                </label>
                <TextField
                  className='form-control'
                  variant='outlined'
                  type={"text"}
                  value={_formData?.name}
                  onChange={(e: any) => changeFormData("name", e.target.value)}
                  error={_regMobileValidate?.name?.error}
                  helperText={_regMobileValidate?.name?.message}
                // onChange={someChangeHandler}
                />
              </div>
              <div className='col-10 col-md-10 mt-4 form-group fontsizing'>
                <label className='form-label'>
                  Primary Contact Person Name
                </label>
                <TextField
                  className='form-control'
                  variant='outlined'
                  type={"text"}
                  value={_formData?.primaryContactPerson}
                  onChange={(e: any) => changeFormData("primaryContactPerson", e.target.value)}
                  error={_regMobileValidate?.primaryContactPerson?.error}
                  helperText={_regMobileValidate?.primaryContactPerson?.message}
                // onChange={someChangeHandler}
                />
              </div>

              <div
                className='text-white col-10 col-md-10 mt-4 bg-dark p-3 d-flex justify-content-center align-items-center'
                style={{ cursor: "pointer" }}
                onClick={() => Registration()}
              //  onClick={() => navigate(ROUTES.REGISTRATION.REGISTERSUCCESS)}
              >
                Create Account
              </div>
            </>
          )}
          <div className='col-10 col-md-10 mt-4 d-flex justify-content-center fontsizing'
            style={{ cursor: "pointer" }}
          >
            <p>Already having an account?</p>
            <a
              href=''
              onClick={() => navigate(ROUTES.LOGIN)}
              className='text-info mx-1'>
              Login
            </a>
          </div>
          <div className='col-10 col-md-10 mt-4 d-flex justify-content-center fontsizing'>
            <a href='' className='text-info'>
              ADMIN
            </a>
          </div>
          <div className='col-10 col-md-10 mt-4 d-flex justify-content-center fontsizing'>
            <p>Version 2.3.1</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
