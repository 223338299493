import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { ROUTES } from "../../../configs/constants";
import Logo from "../../../assets/images/Accuship-Logo.png";

export default function OTP() {
  const navigate = useNavigate();
  const [showselect, setShowselect] = useState("");
  const location = useLocation();
  const hideData = () => {
    var data = "";
    if (location.state.id === "0") {
      data = "markspencer@gmail.com";
    } else {
      data = "91 9443365495";
    }
    const firstFive = data.substring(0, 5);
    const lastTwo = data.slice(-2);
    const middleMasked = "*".repeat(data.length - 7);
    return firstFive + middleMasked + lastTwo;
  };

  return (
    <React.Fragment>
      <div className='w-75 row d-flex justify-content-center align-items-center'>
        <div className='col-12 border rounded d-flex justify-content-center align-items-center flex-column p-4'>
          <img src={Logo} className='w-30 h-30' />
          <h5 className='mt-3'>Verify Your Identity</h5>
          <div className='col-12 col-md-12 mt-5 fontsizing text-center'>
            <div>
              A verification code has been sent to your{" "}
              {location.state.id === "0"
                ? `Email ${hideData()}`
                : `Mobile No ${hideData()}`}
              . Enter the code to verify your ID.
            </div>
          </div>
          <div className='col-12 col-md-12 mt-4 fontsizing'>
            <div className={`form-control`}>
              <MuiOtpInput />
              {/* <div className='otp-input-container'>
                {Array(4)
                  .fill("")
                  .map((_, index) => {
                    return (
                      <TextField
                        style={{
                          textAlign: "center",
                          borderRight: `${
                            index != 3 ? "1px solid black" : "none"
                          }`,
                        }}
                        variant='standard'
                        key={index}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        //   value={value}
                        //   onChange={handleChange}
                        className='otp-input'
                        inputProps={{
                          maxLength: 1,
                        }}
                      />
                    );
                  })}
              </div> */}
            </div>
          </div>
          <div className='col-12 col-md-12 mt-2 d-flex justify-content-between fontsizing'>
            <p>Time Remaining: {}</p>
            <p className='text-muted'>Resend Code</p>
          </div>
          <div className='col-12 col-md-12 mt-4 form-group fontsizing'>
            <div
              className='text-white col-12 col-md-12 mt-4 bg-dark p-3 d-flex justify-content-center align-items-center'
              onClick={() => {
                if (showselect === "0") {
                } else {
                }
              }}>
              Verify Code
            </div>
            <div className='col-12 col-md-12 mt-4 d-flex justify-content-center fontsizing'>
              <p>Back to </p>
              <a
                href=''
                onClick={() => {
                  navigate(ROUTES.LOGIN);
                }}
                className='text-info mx-1'>
                Login
              </a>
            </div>
            <div className='col-12 col-md-12 mt-5 d-flex justify-content-center fontsizing'>
              <a href='' className='text-info'>
                ADMIN
              </a>
            </div>
            <div className='col-12 col-md-12 mt-4 d-flex justify-content-center fontsizing'>
              <p>Version 2.3.1</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
