import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import HeaderMenu from "../includes/HeaderMenu";

export default function DashboardLayout() {
  return (
    <React.Fragment>
      <CssBaseline />
      <div
        className='row vh-100 vw-100 m-0 border-0 p-4 overflow-auto d-flex align-items-center'
        style={{ background: "#0070C1" }}>
        <div className='h-100 col-12 col-md-12 bg-white border rounded align-items-center d-flex flex-column p-0'>
          <HeaderMenu />
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
}
