import { useLocation } from "react-router-dom";
import React from "react";
import moment from "moment";
import 'moment-timezone';


export const userSession = (withToken = false) => {
    const _token: string | null = localStorage.getItem('_token');

    if (_token === null) {
        return null;
    }

    try {
        const user = JSON.parse(atob(_token));
        const response = {
            userId: user?.userId,
            name: user?.name,
            emailAddress: user?.emailAddress,
            mobileNumber: user?.mobileNumber,
            accessToken: null,
        };

        if (withToken) {
            response.accessToken = user?.accessToken;
        }

        return response;
    } catch (error) {
        localStorage.clear();
        return null;
    }

};

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function EncodeUnicode(str: any) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
}

// Decoding base64 ⇢ UTF8

export function DecodeUnicode(str: any) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}

// Get Milliseconds from time frame

export function getMilliseconds(timeString: any) {
    const format = 'DD-MM-YYYY HH:mm:ss';
    const dateTime = moment(timeString, format);
    const milliseconds = dateTime.valueOf();
    return milliseconds;
}


// Date format in DD/MM/YYYY
export const formatDateTime = (date: any) => {
    let dateReturn = '-';
    try {
        if (date !== '' && date !== null) {
            const _date = moment.tz(date, moment.tz.guess()).toDate();
            dateReturn = moment(_date).format('DD-MMM-YYYY hh:mm:ss A');
        }
    } catch (e) {
        dateReturn = '-';
    }

    return dateReturn;
}

export const formatDate = (date: any) => {
    let dateReturn = '-';
    try {
        if (date !== '' && date !== null) {
            const _date = moment.tz(date, moment.tz.guess()).toDate();
            dateReturn = moment(_date).format('DD-MMM-YYYY');
        }
    } catch (e) {
        dateReturn = '-';
    }

    return dateReturn;
}

export const validemail = (email: string | null | undefined): boolean =>{
    let isValid = true;

    if (!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
        isValid = false;
    }

    return isValid;
}