import { TextField } from "@mui/material";
import React, { useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../configs/constants";
import Logo from "../../../assets/images/Accuship-Logo.png";
import Verifysms from "../../../assets/images/Verify sms.png";
import Verifyemail from "../../../assets/images/verify email.png";

export default function LoginVerification() {
  const [showselect, setShowselect] = useState("");
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className='w-75 row d-flex justify-content-center align-items-center'>
        <div className='col-12 border rounded d-flex justify-content-center align-items-center flex-column p-4'>
          <img src={Logo} className='w-30 h-30' />
          <h5 className='mt-5'>Verify Your Identity</h5>
          <div className='col-12 col-md-12 mt-5 fontsizing'>
            <label className='form-label'>Get OTP Verification</label>
            <div
              className={`form-control p-3 ${
                showselect === "0" ? "border border-info" : ""
              }`}>
              <label className='radio-inline control-label d-flex justify-content-start align-items-center'>
                <TextField
                  name='verifymethod'
                  variant='standard'
                  type='radio'
                  value={0}
                  onChange={(e) => {
                    setShowselect(e.target.value);
                  }}
                  className='border-0'
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <img src={Verifyemail} className='mx-3' />
                <div className='mx-4'>Verify via Email</div>
              </label>
            </div>
          </div>
          <div className='col-12 col-md-12 mt-4 fontsizing'>
            <div
              className={`form-control p-3 ${
                showselect === "1" ? "border border-info" : ""
              }`}>
              <label className='radio-inline control-label d-flex justify-content-start align-items-center'>
                <TextField
                  name='verifymethod'
                  variant='standard'
                  type='radio'
                  value={1}
                  onChange={(e) => {
                    setShowselect(e.target.value);
                  }}
                  className='border-0'
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <img src={Verifysms} className='mx-3' />
                <div className='mx-4'>Verify via SMS</div>
              </label>
            </div>
          </div>
          <div className='col-12 col-md-12 mt-4 form-group fontsizing'>
            <div
              className='text-white col-12 col-md-12 mt-4 bg-dark p-3 d-flex justify-content-center align-items-center'
              onClick={() => {
                navigate(ROUTES.OTPVERIFY, {
                  state: { id: showselect },
                });
              }}>
              Send Code
            </div>
            <div className='col-12 col-md-12 mt-4 d-flex justify-content-center fontsizing'>
              <p>Back to </p>
              <a
                href=''
                onClick={() => {
                  navigate(ROUTES.LOGIN);
                }}
                className='text-info mx-1'>
                Login
              </a>
            </div>
            <div className='col-12 col-md-12 mt-5 d-flex justify-content-center fontsizing'>
              <a href='' className='text-info'>
                ADMIN
              </a>
            </div>
            <div className='col-12 col-md-12 mt-4 d-flex justify-content-center fontsizing'>
              <p>Version 2.3.1</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
