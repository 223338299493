import React from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import LoginBanner from "../../assets/images/Login Banner image.png";

export default function AuthLayout() {
  return (
    <React.Fragment>
      <CssBaseline />
      <main className='row'>
        <div
          className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-end vh-100'
          style={{ background: "#0070C1" }}>
          <img src={LoginBanner} width={"100%"} className='col-12 col-md-12' />
        </div>
        <div className='h-100 mh-100 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center p-4 align-items-center overflow-hidden'>
          <Outlet />
        </div>
      </main>
    </React.Fragment>
  );
}
