import { TextField } from "@mui/material";
import React, { useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../configs/constants";
import Logo from "../../../assets/images/Accuship-Logo.png";
import Verifysms from "../../../assets/images/Verify sms.png";
import { MuiOtpInput } from "mui-one-time-password-input";
import Verifyemail from "../../../assets/images/verify email.png";
import { verifyLoginOtp } from "../../../models/model";
import Swal from 'sweetalert2';


export default function LoginOtpVerify() {
  const [showselect, setShowselect] = useState("");
  const [_buttonLoading, _setbuttonLoading] = useState(false);
  const navigate = useNavigate();

  const [_formData, _setFormData] = useState<any>({
    mobileOrEmail: "",
    OTP: "",
  });

  
  const changeFormData = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
  };

  const loginValidate = {
    mobileOrEmail: { error: false, message: "" },
    OTP: { error: false, message: "" },
  }

  const [_loginValidate, setLoginevalidate] = useState(loginValidate);

  const userLoginValidate = () => {
    debugger;
    const _validate: any = Object.assign({}, loginValidate);
    let valid = true;

    if (_formData.mobileOrEmail === "" || _formData.mobileOrEmail === 0) {
      _validate.mobileOrEmail.error = true;
      _validate.mobileOrEmail.message = "Required Field";
      valid = false;
    }

    setLoginevalidate(_validate);
    if (!valid) {
      _setbuttonLoading(false);
      return;
    }
    _setbuttonLoading(true);

    loginVerify()
    // SetactiveStep(1)
  }

  const loginVerify = () => {
    debugger;
    verifyLoginOtp(_formData?.mobileOrEmail, _formData?.OTP)
      .then(response => {
        if (response.data.status === true) {
          Swal.fire({
            title: 'Success!',
            text: 'Login Successful!',
            icon: 'success',
            confirmButtonText: 'OK',
          });

          _setFormData({
            ..._formData,
            OTP: "",
          });

          navigate(ROUTES.DASHBOARD.DASHBOARD)

          console.log("success");
        }
        else {
          console.log("bad request");
        }
      })
      .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
  }




  return (
    <React.Fragment>
      <div className='w-75 row d-flex justify-content-center align-items-center'>
        <div className='col-12 border rounded d-flex justify-content-center align-items-center flex-column p-4'>
          <img src={Logo} className='w-30 h-30' />
          <h5 className='mt-5'>Verify Your Identity.</h5>


          <div className='col-12 col-md-12 mt-5 fontsizing text-center'>
            <div>
              A verification code has been sent to your registered Mobile and Email
              {/* {location.state.id === "0"
                ? `Email ${hideData()}`
                : `Mobile No ${hideData()}`} */}
              . Enter the code to verify your ID.
            </div>
          </div>

          <div className='col-12 col-md-12 mt-4 fontsizing'>
            <div className={`form-control`}>
              {/* <MuiOtpInput /> */}

              <input
                      type={"text"}
                      maxLength={6}
                      value={_formData?.OTP}
                      onChange={(e: any) => changeFormData("OTP", e.target.value)}
                      className='col-12 col-md-12 p-3'
                      style={{
                        border: "none",
                        letterSpacing: "2rem",
                        fontSize: "medium",
                        caretColor: "black",
                      }}
                    />
              {/* <div className='otp-input-container'>
                {Array(4)
                  .fill("")
                  .map((_, index) => {
                    return (
                      <TextField
                        style={{
                          textAlign: "center",
                          borderRight: `${
                            index != 3 ? "1px solid black" : "none"
                          }`,
                        }}
                        variant='standard'
                        key={index}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        //   value={value}
                        //   onChange={handleChange}
                        className='otp-input'
                        inputProps={{
                          maxLength: 1,
                        }}
                      />
                    );
                  })}
              </div> */}
            </div>
          </div>
          <div className='col-12 col-md-12 mt-2 d-flex justify-content-between fontsizing'>
            <p>Time Remaining: {}</p>
            <p className='text-muted'>Resend Code</p>
          </div>


          {/* <div className='col-12 col-md-12 mt-5 fontsizing'>
            <label className='form-label'>Get OTP Verificationnn</label>
            <div
              className={`form-control p-3 ${
                showselect === "0" ? "border border-info" : ""
              }`}>
              <label className='radio-inline control-label d-flex justify-content-start align-items-center'>
                <TextField
                  name='verifymethod'
                  variant='standard'
                  type='radio'
                  value={0}
                  onChange={(e) => {
                    setShowselect(e.target.value);
                  }}
                  className='border-0'
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <img src={Verifyemail} className='mx-3' />
                <div className='mx-4'>Verify via Email</div>
              </label>
            </div>
          </div> */}
          {/* <div className='col-12 col-md-12 mt-4 fontsizing'>
            <div
              className={`form-control p-3 ${
                showselect === "1" ? "border border-info" : ""
              }`}>
              <label className='radio-inline control-label d-flex justify-content-start align-items-center'>
                <TextField
                  name='verifymethod'
                  variant='standard'
                  type='radio'
                  value={1}
                  onChange={(e) => {
                    setShowselect(e.target.value);
                  }}
                  className='border-0'
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <img src={Verifysms} className='mx-3' />
                <div className='mx-4'>Verify via SMS</div>
              </label>
            </div>
          </div> */}
          <div className='col-12 col-md-12 mt-4 form-group fontsizing'>
            <div
              className='text-white col-12 col-md-12 mt-4 bg-dark p-3 d-flex justify-content-center align-items-center'
              onClick={() => {
                userLoginValidate()
                // navigate(ROUTES.DASHBOARD.DASHBOARD, {
                //   state: { id: showselect },
                // });
              }}>
              Verify Code
            </div>
            <div className='col-12 col-md-12 mt-4 d-flex justify-content-center fontsizing'>
              <p>Back to </p>
              <a
                href=''
                onClick={() => {
                  navigate(ROUTES.LOGIN);
                }}
                className='text-info mx-1'>
                Login
              </a>
            </div>
            <div className='col-12 col-md-12 mt-5 d-flex justify-content-center fontsizing'>
              <a href='' className='text-info'>
                ADMIN
              </a>
            </div>
            <div className='col-12 col-md-12 mt-4 d-flex justify-content-center fontsizing'>
              <p>Version 2.3.1</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
